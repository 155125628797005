body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#widget-root {
  color: white;
  font-family: Calibri;
}

#widget-container {
  height: 30vh;
  width: 50vh;
  background-size: cover;
  line-height: 50vh;
}

#nav {
  height: 10vh;
  background-color: rgb(221, 139, 238);
  width: 100%;
  margin-bottom: 2vh;
}

.list-item-even {
  background-color: rgb(220,220,220);
  border: 1px solid rgb(221, 139, 238);
  border-radius: 3px;
}
.list-item-odd {
  background-color: rgb(255, 255, 255);
  border: 1px solid rgb(221, 139, 238);
  border-radius: 3px;
}

.test-widget {
  margin-bottom: 2vh;
}